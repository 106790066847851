<template>
  <v-app style="background-color: white;">
    <v-app-bar flat rounded="b-xl" color="white" :border="true" class="header">
      <template v-slot:prepend>
        <ClientOnly>
          <NuxtLink to="/" class="d-block">
            <Logo />
          </NuxtLink>
        </ClientOnly>
      </template>
      <template v-slot:append>
        <template v-if="!mobile">
          <NuxtLink @click="scrollTo('inquiry')">
            <TextButton class="mx-3">How it works</TextButton>
          </NuxtLink>
          <NuxtLink @click="scrollTo('inquiry')">
            <TextButton class="mx-3">Our advantages</TextButton>
          </NuxtLink>
          <NuxtLink @click="scrollTo('inquiry')">
            <PrimaryButton class="mx-3">Get Demo</PrimaryButton>
          </NuxtLink>
          <v-divider length="44" vertical class="h-100 align-self-center mx-5"></v-divider>
        </template>
        <div v-if="mobile">
          <button @click="drawer = !drawer">
            <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM7 12C7 11.4477 7.44772 11 8 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H8C7.44772 13 7 12.5523 7 12ZM13 18C13 17.4477 13.4477 17 14 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H14C13.4477 19 13 18.5523 13 18Z"
                fill="#3C9273" />
            </svg>
          </button>
        </div>
      </template>
    </v-app-bar>
    <v-main class="main">
      <div class="signup-background">
        <slot></slot>
        <div class="footer">
          <div class="flex-col">
            <div class="logo-wrapper">
              <Logo />
            </div>
            <div style="width: 200px">
              <SocialLinks />
            </div>
            <div>
              <FooterCopy />
            </div>
          </div>
          <div class="flex-col">
            <div>
              <FooterMenu />
            </div>
          </div>
        </div>
      </div>
    </v-main>
    <ClientOnly>
      <v-navigation-drawer v-model="drawer" temporary location="right">
        <div class="mobile-menu-wrapper">
          <div class="mobile-menu">
            <div class="mobile-menu__item">
              <NuxtLink to="#">How it works</NuxtLink>
            </div>
            <div class="mobile-menu__item">
              <NuxtLink to="#">Our advantages</NuxtLink>
            </div>
            <div class="mobile-menu__item">
              <NuxtLink to="/signup">Get Demo</NuxtLink>
            </div>
          </div>
        </div>
      </v-navigation-drawer>
    </ClientOnly>
  </v-app>
</template>
<script lang="ts" setup>
import Logo from '~/components/layout/Logo.vue'
import SocialLinks from '~/components/layout/SocialLinks.vue'
import FooterCopy from '~/components/layout/FooterCopy.vue'
import FooterMenu from '~/components/layout/FooterMenu.vue'
import TextButton from '~/components/shared/buttons/TextButton.vue'
import SecondaryButton from '~/components/shared/buttons/SecondaryButton.vue'
import PrimaryButton from '~/components/shared/buttons/PrimaryButton.vue'
import { scrollTo } from '~/utils/scrollUtils';
import { useDisplay } from 'vuetify';
import { ref } from 'vue'
const { mobile } = useDisplay();
const drawer = ref(false)
</script>

<style lang="scss" scoped>
.flex-col {
  flex-grow: 1;
}
.footer {
  text-align: start;
  padding: 20px;
  margin: 40px 20px 20px 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.signup-background {
  position: relative;
  //min-height: 617px;
  overflow: hidden;
  background: url('@/assets/img/main-banner-trees-1.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: calc(100%) calc(100% + 62px);
  &::before {
    display: block;
    content: '';
    bottom: 0;
    right: 0;
    background: url('@/assets/img/main-banner-trees-1.png') left bottom no-repeat;
    background-blend-mode: darken;
    background-size: 1100px, auto;
    background-position: calc(100%) calc(100% + 62px);
    pointer-events: none;
    position: absolute;
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    height: 435.1px;
    top: 237.62px;
    left: -451px;
  }


  &::after {
    overflow: hidden;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('@/assets/img/action-banner-bg.png') right bottom no-repeat;
    background-blend-mode: darken;
    background-size: 500px, auto;
    background-position: right -150px bottom -230px;
    pointer-events: none;
    opacity: 0.1;
  }
}
</style>